import { toMeta, toPage, toResponsiveImage } from "../adapter";
import { toMatchDetails } from "../match/adapter";

const replacePlaceholders = ({ input, name }) =>
  input.replace(/{competitionName}/g, name);

export const toCompetitionPage = ({ page, competition }) => {
  if (page) {
    return {
      ...toPage(page),
      meta: {
        ...toMeta(page.meta),
        title: replacePlaceholders({
          input: page.meta.title,
          name: competition.name,
        }),
        description: replacePlaceholders({
          input: page.meta.description,
          name: competition.name,
        }),
        thumbnail: competition.logoUrl,
      },
      competition,
    };
  }
  return null;
};

const toCompetition = (data) =>
  data
    ? {
        id: data.competitionTypeId,
        name: data.title,
        logoUrl: data.logoImageUrl,
        themeColor1: data.themeColorFirst,
        themeColor2: data.themeColorSecond,
        isCup: data.isCup,
        banner:
          data.banners &&
          data.banners.length &&
          toResponsiveImage(data.banners[0].images),
        season: data.season,
        gameWeek: data.gameWeek,
        isHidden: data.isHidden,
        matchPageSize: data.matchPageSize || data.pageSize,
        hasLive: data.liveMatches,
        isSuperCup: data.superCup,
      }
    : null;

export const toCompetitionDetails = (data) => toCompetition(data);

export const toCompetitions = (data) =>
  data ? data.map((n) => toCompetition(n)) : [];

export const toMatches = (data) =>
  data ? data.map((n) => toMatchDetails(n)) : [];

const toNewsCategory = (data) =>
  data
    ? {
        id: data.id,
        title: data.title,
        url: data.url,
      }
    : null;

export const toCompetitionNews = (data) =>
  data
    ? data.map((n) => ({
        ...toPage(n),
        summary: n.summary,
        tags: n.tags && n.tags.filter((m) => m),
        date: n.date,
        isFeatured: n.isFeatured,
        category: toNewsCategory(n.category),
        video: n.video,
      }))
    : [];

export const toClub = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        logoUrl: data.image,
      }
    : null;

export const toClubs = (data) =>
  data
    ? data.map((n) => ({
        id: n.competitionTypeId,
        name: n.competitionName,
        competitionType: n.competitionTypeName,
        data: n.data.map((m) => toClub(m)),
      }))
    : null;

const toTable = (data) =>
  data
    ? data.map((n) => ({
        id: n.position,
        teamId: n.club.id,
        name: n.club.name,
        logoUrl: n.club.image,
        pos: n.position,
        mp: n.matchesPlayed,
        w: n.wins,
        d: n.draws,
        l: n.losses,
        gd: n.goalDifference,
        pts: n.points,
        next: toClub(n.nextMatch),
        isLive: n.club.isLive,
      }))
    : [];

const toGroups = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        title: n.competitionName,
        table: toTable(n.data),
      }))
    : [];

export const toStandings = (data) =>
  data && data.phases ? data.phases.map((n) => toGroups(n.groups)) : [];

const toVenue = (data) =>
  data
    ? {
        id: data.id,
        name: data.name,
        images: toResponsiveImage(data.images),
      }
    : null;

export const toCup = (data) =>
  data && data.matchStanding
    ? data.matchStanding.map((n) => ({
        id: n.id,
        homeTeam: toClub(n.homeTeam),
        awayTeam: toClub(n.awayTeam),
        order: n.order,
        gameWeek: n.gameWeek,
        startDate: n.startDate,
        venue: toVenue(n.venue),
        isLive: n.isLive,
      }))
    : null;

export const toHighlightsItems = (data) =>
  data
    ? data.map((n) => {
        return {
          id: n.id.videoId,
          description: n.snippet.description,
          title: n.snippet.title,
          datePublished: n.snippet.publishedAt,
          thumbnails: Object.entries(n.snippet.thumbnails).map(([, value]) => ({
            imageUrl: value.url,
            width: value.width,
            height: value.height,
          })),
        };
      })
    : [];

export const toHighlights = (data) =>
  data
    ? {
        highlights: toHighlightsItems(data.items),
        nextPageToken:
          Boolean(data.items.length) &&
          Boolean(data.items.length > 12) &&
          data.nextPageToken,
      }
    : null;

export const toTopScorers = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        profilePhotoUrl: n.profilePhoto,
        team: toClub(n.team),
        mp: n.matchesPlayed,
        goals: n.goals,
      }))
    : null;

export const toSponsors = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        name: n.name,
        url: n.url,
        tier: n.tier,
        image: n.logo && n.logo.imageUrl,
      }))
    : null;

export const toSeasons = (data) =>
  data
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
      }))
    : null;

export const toNextPage = ({ page, pageCount }) => {
  const pageCountAsInt = parseInt(pageCount, 10);
  if (
    pageCountAsInt &&
    !Number.isNaN(pageCountAsInt) &&
    pageCountAsInt > 1 &&
    page < pageCountAsInt
  ) {
    return page + 1;
  }

  return null;
};

export const toGameWeeks = (data) =>
  data && data.gameWeeks ? data.gameWeeks[data.gameWeeks.length - 1] : -1;
